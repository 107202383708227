import "./CarListItem.css";
import { Tag } from "antd";

const CarListItem = ({ rental, detailsUrl, price, make, model, photoUrl }) => {
  const { rentalName, mainUrl, location } = rental;
  return (
    // eslint-disable-next-line
    // TODO: remove rel="noopener noreferrer" when going out
    <a
      className="car-list-item"
      href={mainUrl + detailsUrl}
      target="_blank"
      rel="noreferrer noopener"
    >
      <div className="car-list-item-left">
        <img width="100" src={photoUrl} alt={make + " " + model} />
      </div>
      <div className="car-list-item-right">
        <div className="car-list-item-content-top">
          <div className="car-list-item-content-top-left">
            {make} {model}
          </div>
          <div className="car-list-item-content-top-right">
            {price.day} <small>netto PLN / dzień</small>
          </div>
        </div>
        <div className="car-list-item-content-bottom">
          <Tag color="">{rentalName}</Tag>
          <Tag color="">{location}</Tag>
        </div>
      </div>
    </a>
  );
};

export default CarListItem;
