import React from "react";
import { List, Breadcrumb } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import CarListItem from "./CarListItem";

const Home = () => {
  const [rentalsWithCars, setRentalsWithCars] = useState("");

  useEffect(() => {
    const fetchApi = async () => {
      const responseRentals = await axios.get("/api/rentals.json");
      const responseCars = await axios.get("/api/cars.json");

      const rentals = responseRentals.data;
      const cars = responseCars.data;

      let finalRentals = [];

      rentals.forEach((rental) => {
        if (!rental.cars || !rental.cars.length) return;
        rental.cars.forEach((rentalCar) => {
          finalRentals.push({
            ...rentalCar,
            ...cars.find((car) => rentalCar.carId === car.id),
            rental,
          });
        });
      });

      setRentalsWithCars(finalRentals);
    };

    fetchApi();
  }, []);

  console.log(rentalsWithCars);

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        {/*<Breadcrumb.Item>Home</Breadcrumb.Item>*/}
        {/*<Breadcrumb.Item>List</Breadcrumb.Item>*/}
      </Breadcrumb>
      <div className="site-layout-content">
        <List
          itemLayout="vertical"
          size="large"
          pagination={{
            onChange: (page) => {
              console.log(page);
            },
            pageSize: 10,
          }}
          dataSource={rentalsWithCars}
          renderItem={(item) => (
            <List.Item key={item.id} actions={[]}>
              <CarListItem {...item} />
              {/*{JSON.stringify(item)}*/}
            </List.Item>
          )}
        />
      </div>
    </>
  );
};

export default Home;
