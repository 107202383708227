import { Layout, Menu } from "antd";
import logo from "../logo.svg";
const { Header, Content } = Layout;

const L = ({ children }) => {
  return (
    <Layout className="layout">
      <Header>
        <div>
          <img src={logo} className="logo" alt="logo" />
          <span className="logo-name">
            <div className="title">Fura Na Wynajem</div>
            <div className="subtitle">
              Wyszukiwarka wypożyczalni samochodów sportowych
            </div>
          </span>
        </div>
        <Menu mode="horizontal" style={{ float: "right" }}>
          {/*<Menu.Item key="1">nav 1</Menu.Item>*/}
          {/*<Menu.Item key="2">nav 2</Menu.Item>*/}
          {/*<Menu.Item key="3">nav 3</Menu.Item>*/}
        </Menu>
      </Header>
      <Content className="content">{children}</Content>
      {/*<Footer style={{ textAlign: 'center' }}>FNW ©2021</Footer>*/}
    </Layout>
  );
};

export default L;
